import { AccountingKpis } from '@gozoki/api-types';
import ExcelJS from 'exceljs';

const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés à partir de 0 en JavaScript
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
};

export const writeAccountingFiles = async (accountingKpis: AccountingKpis[]) => {
    // Création du fichier zip pour mettre tous les fichiers dedans ensuite
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Comptabilité');

    // Ajoutez l'en-tête de la feuille
    sheet.columns = [
        { header: 'Date de la vente', key: 'date', width: 15 },
        { header: 'Code journal', key: 'code', width: 15 },
        { header: 'Comptes comptables', key: 'accounts', width: 20 },
        { header: 'Libellé', key: 'label', width: 30 },
        { header: 'Débit', key: 'debit', width: 15 },
        { header: 'Crédit', key: 'credit', width: 15 },
    ];

    accountingKpis.forEach((kpi) => {
        const formattedDate = formatDate(kpi.date);
        const data = [
            {
                date: '',
                code: '',
                accounts: '',
                label: '',
                debit: '',
                credit: '',
            },
            {
                date: formattedDate,
                code: 'CAI',
                accounts: '70711100',
                label: 'vente négoce interne (HT)',
                debit: '',
                credit: kpi.main.internalNegoceHt,
            },
            {
                date: formattedDate,
                code: 'CAI',
                accounts: '44571000',
                label: 'TVA (vente négoce interne)',
                debit: '',
                credit: kpi.main.internalNegoceTva,
            },
            {
                date: formattedDate,
                code: 'CAI',
                accounts: '70712000',
                label: 'vente négoce externe (HT)',
                debit: '',
                credit: kpi.main.externalNegoceHt,
            },
            {
                date: formattedDate,
                code: 'CAI',
                accounts: '44571000',
                label: 'TVA (vente négoce externe)',
                debit: '',
                credit: kpi.main.externalNegoceTva,
            },
            {
                date: formattedDate,
                code: 'CAI',
                accounts: '46700000',
                label: 'vente sushis (TTC)',
                debit: '',
                credit: kpi.main.sushisTtc,
            },
            {
                date: formattedDate,
                code: 'CAI',
                accounts: '58100000',
                label: 'ventes CB',
                debit: kpi.main.amountCb,
                credit: '',
            },
            {
                date: formattedDate,
                code: 'CAI',
                accounts: '58200000',
                label: 'ventes tickets resto',
                debit: kpi.main.amountConecs,
                credit: '',
            },
            {
                date: formattedDate,
                code: 'CAI',
                accounts: '41600000',
                label: 'impayés client (TTC)',
                debit: kpi.main.unPaidAmount,
                credit: '',
            },
            {
                date: formattedDate,
                code: 'CAI',
                accounts: '70970000',
                label: 'remises (HT)',
                debit: kpi.main.promoAmountHt,
                credit: '',
            },
            {
                date: formattedDate,
                code: 'CAI',
                accounts: '44571000',
                label: 'TVA remises',
                debit: kpi.main.promoAmountTva,
                credit: '',
            },
        ];

        data.forEach((row) => {
            sheet.addRow(row);
        });
    });

    // Insérez les données dans la feuille

    const content = await workbook.xlsx.writeBuffer();
    const url = URL.createObjectURL(new Blob([content]));
    const link = document.createElement('a');
    link.href = url;
    link.download = `donnees-comptabilité_${accountingKpis[0].date}.xlsx`;
    link.click();
    URL.revokeObjectURL(url);
};
