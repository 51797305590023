import { PromoCampaignForm } from '../forms/usePromoCampaignForm';
import { gozokiApiWithAuth } from '../gozokiApi';

// Create a promotional campaign
export const createPromoCampaign = async (data: PromoCampaignForm) => {
    const response = await gozokiApiWithAuth.post('/promo-campaigns', data);
    return response;
};

// Destroy a promotional campaign
export const destroyPromoCampaign = async (id: number) => {
    const response = await gozokiApiWithAuth.delete(`/promo-campaigns/${id}`);
    return response;
};

// Destroy multiple promotional campaigns
export const destroyPromoCampaigns = async (ids: number[]) => {
    const responses = await Promise.all(ids.map((id) => destroyPromoCampaign(id)));
    return responses;
};

// Update a promotional campaign
export const updatePromoCampaign = async ({ id, data }: UpdatePromoCampaignArgs) => {
    const response = await gozokiApiWithAuth.patch(`/promo-campaigns/${id}`, data);
    return response;
};

export const userActivatePromoCode = async (id: number) => {
    const response = await gozokiApiWithAuth.post(`/me/promo-codes/activate/${id}`);
    return response;
};

interface UpdatePromoCampaignArgs {
    id: number;
    data: PromoCampaignForm;
}
