import {
    disableSupportMode,
    enableSupportMode,
    removeArticleFromKiosk,
    useCart,
    useKiosk,
    validateArticleFromKiosk,
} from '@gozoki/api';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReactEventHandler, useEffect, useState } from 'react';
import { useToast } from '../../components/communication/Toast';
import Page from '../../components/Page';
import { KiosksPicker } from '../../utils/dashboard/kiosksPicker';
import { getErrorMessageWithSentry } from '../../utils/sentry';
import { fonts } from '../../utils/theme/fonts.theme';

const FooterKioskControlTable = ({
    total,
    onClick,
}: {
    total: number | undefined;
    onClick: ReactEventHandler;
}) => {
    return (
        <Box pt="1em" display="flex" justifyContent="center">
            <Box display="flex" justifyContent="space-between" width="100%">
                <Typography lineHeight="2em">TOTAL</Typography>
                <Typography lineHeight="2em">{total}&nbsp;€</Typography>
            </Box>
            <Box width="20em" display="flex" justifyContent="end">
                <Button onClick={onClick} variant="contained">
                    Valider le panier
                </Button>
            </Box>
        </Box>
    );
};

const KiosksControlPage = () => {
    const [Toast, showToast] = useToast();
    const [kioskId, setKioskId] = useState<number | undefined>(() => {
        const data = localStorage.getItem('kiosks_control_page__kiosk_id');
        return data ? JSON.parse(data) : undefined;
    });
    useEffect(() => {
        if (kioskId != null) {
            localStorage.setItem('kiosks_control_page__kiosk_id', JSON.stringify(kioskId));
        } else {
            localStorage.removeItem('kiosks_control_page__kiosk_id');
        }
    }, [kioskId]);

    const { data: kiosk } = useKiosk(kioskId ?? Number.NaN);
    const { mutate: enableSupport } = useMutation(enableSupportMode, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['kiosk', kioskId] });
        },
        onError: (error) => {
            showToast({ message: getErrorMessageWithSentry(error), severity: 'error' });
        },
    });
    const queryClient = useQueryClient();
    const { mutate: disableSupport } = useMutation(disableSupportMode, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['kiosk', kioskId] });
        },
    });
    const { mutate: removeArticle } = useMutation(removeArticleFromKiosk, {
        onSuccess: () => {
            if (kiosk) {
                queryClient.invalidateQueries({ queryKey: ['cart', kiosk.cartId] });
            }
        },
    });
    const { mutate: validateArticle } = useMutation(validateArticleFromKiosk, {
        onSuccess: () => {
            if (kiosk) {
                queryClient.invalidateQueries({ queryKey: ['cart', kiosk.cartId] });
            }
        },
    });
    const { data: cart } = useCart(kiosk?.cartId ?? Number.NaN);

    return (
        <Page>
            <Toast />
            <Box display="flex">
                <Typography sx={fonts.pageTitle} flexGrow={1}>
                    Contrôle panier
                </Typography>
            </Box>
            <Paper
                sx={{
                    padding: '16px',
                }}
            >
                <KiosksPicker
                    disabled={kiosk && kiosk.supportMode}
                    selectedKiosk={kioskId}
                    setUniqueKiosk={setKioskId}
                />
                {kiosk && !kiosk.supportMode && (
                    <Button
                        sx={{ marginLeft: '5px' }}
                        onClick={() => kioskId && enableSupport(kioskId)}
                        variant="contained"
                    >
                        Passer en mode support
                    </Button>
                )}
            </Paper>

            {kiosk && kiosk.supportMode && (
                <Box display="flex" pt="30px">
                    <Typography sx={fonts.pageSubtitle} flexGrow={1}>
                        Panier en cours
                    </Typography>
                </Box>
            )}

            {kiosk && kiosk.supportMode && cart && (
                <Paper
                    sx={{
                        padding: '16px',
                    }}
                >
                    {cart?.articles.map((article) => {
                        return (
                            <Box
                                key={article.id}
                                display="flex"
                                justifyContent="space-between"
                                pt="0.5em"
                            >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    width="100%"
                                    px="16px"
                                    sx={{
                                        ...(!article.isConfirmed && {
                                            border: 'solid 1px #FF9900',
                                            borderRadius: '8px',
                                            padding: '16px',
                                        }),
                                    }}
                                >
                                    <Box display="flex">
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="center"
                                            mr="2em"
                                        >
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                width="24px"
                                                height="24px"
                                                color="#21492C"
                                                lineHeight="24px"
                                                sx={{
                                                    outline: 'solid 1px #21492C',
                                                }}
                                            >
                                                1
                                            </Box>
                                        </Box>
                                        <img
                                            src={article.product.optimizedPreviewImageUrl ?? ''}
                                            alt=""
                                            style={{
                                                width: '48px',
                                                height: '48px',
                                            }}
                                        />
                                        <Typography pl="1em" lineHeight="48px">
                                            {article.product.label}
                                        </Typography>
                                    </Box>
                                    <Typography pl="1em" lineHeight="48px">
                                        {article.soldPrice}&nbsp;€
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    {!article.isConfirmed && (
                                        <Button
                                            sx={{ width: '18em', backgroundColor: '#FF9900' }}
                                            variant="contained"
                                            onClick={() =>
                                                kioskId &&
                                                validateArticle({
                                                    id: kioskId,
                                                    articleId: article.id,
                                                })
                                            }
                                        >
                                            <AddShoppingCartIcon sx={{ marginRight: '6px' }} />
                                            Confirmer l'ajout au panier
                                        </Button>
                                    )}
                                    <Button
                                        sx={{ width: '20em', color: '#1D1B19' }}
                                        onClick={() =>
                                            kioskId &&
                                            removeArticle({
                                                id: kioskId,
                                                articleRef: article.checkpointArticleRef,
                                            })
                                        }
                                    >
                                        <DeleteIcon sx={{ marginRight: '6px' }} />
                                        Supprimer du panier
                                    </Button>
                                </Box>
                            </Box>
                        );
                    })}

                    <Box pt="3em" />
                    <Divider />
                    <FooterKioskControlTable
                        total={cart && cart.amountTotal}
                        onClick={() => kioskId && disableSupport(kioskId)}
                    />
                </Paper>
            )}
            {kiosk && kiosk.supportMode && !cart && (
                <Paper
                    sx={{
                        padding: '16px',
                    }}
                >
                    <Box display="flex" justifyContent="center" pb="1em" color="#787878">
                        Panier vide
                    </Box>
                    <Divider />
                    <FooterKioskControlTable
                        total={0}
                        onClick={() => kioskId && disableSupport(kioskId)}
                    />
                </Paper>
            )}
        </Page>
    );
};

export default KiosksControlPage;
