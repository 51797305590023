import { Alert, Button, IconButton, Typography } from '@mui/material';
import { ButtonOverrides, TextOverrides } from '../../utils/theme/overrides.theme';
import { CAMPAIGN_TARGETS, getStoreTargetRefs, getTargetIds } from '@gozoki/api-types';
import { MENUS, asStringNumber } from '@gozoki/tools';
import {
    createPromoCampaign,
    updatePromoCampaign,
    useAppUsers,
    useProducts,
    usePromoCampaign,
    usePromoCampaignForm,
    useStores,
    zPromoCampaignForm,
} from '@gozoki/api';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DatePickerRhf from '../../components/inputs/DatePickerRhf';
import LoadingPaper from '../../components/containers/LoadingPaper';
import { MenuOption } from '../../components/containers/MenuOptions';
import Page from '../../components/Page';
import { ROUTES } from '../../utils/constants/routes.constants';
import RadioGroupRhf from '../../components/inputs/RadioGroupRhf';
import SelectMultipleRhf from '../../components/inputs/SelectMultipleRhf';
import SelectRhf from '../../components/inputs/SelectRhf';
import TextFieldRhf from '../../components/inputs/TextFieldRhf';
import { ThemeColors } from '../../utils/theme/colors.theme';
import { fonts } from '../../utils/theme/fonts.theme';
import { getErrorMessageWithSentry } from '../../utils/sentry';
import { useMutation } from '@tanstack/react-query';
import { useRights } from '../../utils/hooks';
import { useToast } from '../../components/communication/Toast';
import { useWatch } from 'react-hook-form';

const typeOptions: MenuOption[] = CAMPAIGN_TARGETS.map((target) => ({
    label: target,
    value: target,
}));

const AddPromoCampaignPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const updateMode = id !== undefined; // Update mode if there is a promo campaign ID in the URL

    // If this is update mode, use isLoading to show a loading indicator before the data arrives
    const { data, isLoading } = usePromoCampaign(parseInt(id ?? '', 10));

    const { control, trigger, watch, getValues, setValue } = usePromoCampaignForm();
    const type = useWatch({ control, name: 'type' });

    const products = useProducts();
    const users = useAppUsers();
    const stores = useStores();
    const [Toast, showToast] = useToast();

    const storesData = useMemo(
        () => stores.data?.filter((s) => s.storeType === 'BOUTIQUE') ?? [],
        [stores.data]
    );

    const isAdmin = useRights(['ADMIN']);
    // Fill the form with initial data once the data is fetched
    useEffect(() => {
        // Set all values
        if (data) {
            setValue('name', data.name);
            setValue('type', data.type);
            setValue('targetIds', getTargetIds(data));
            setValue('storeTargetRefs', getStoreTargetRefs(data));
            setValue('reductionType', data.reductionType);
            setValue('reductionValue', asStringNumber(data.reductionValue));
            setValue('startDate', data.startDate);
            setValue('endDate', data.endDate);
            setValue('campaignType', data.isPublic ? 'public' : 'private');
            setValue('publicCode', data.publicCode);
        }
    }, [setValue, data]);

    const navigateBack = useCallback(() => {
        navigate(ROUTES.PROMO_CAMPAIGN);
    }, [navigate]);

    const productOptions: MenuOption[] = useMemo(() => {
        const baseProductsChoices: MenuOption[] = [];
        const categoriesOptions: Record<
            number,
            { label: string; value: number[]; isHelper: true }
        > = {};
        const negoceOptions: Record<string, { label: string; value: number[]; isHelper: true }> =
            {};
        products.data?.forEach((product) => {
            baseProductsChoices.push({
                label: product.label,
                value: product.id,
            });
            product.categories.forEach((category) => {
                if (!categoriesOptions[category.id]) {
                    categoriesOptions[category.id] = {
                        label: category.name,
                        value: [],
                        isHelper: true,
                    };
                }
                categoriesOptions[category.id].value.push(product.id);
            });
            if (!negoceOptions[product.negoceType]) {
                negoceOptions[product.negoceType] = {
                    label: product.negoceType,
                    value: [],
                    isHelper: true,
                };
            }
            negoceOptions[product.negoceType].value.push(product.id);
        });
        const allChoices: MenuOption[] = [];
        Array.from(Object.values(categoriesOptions)).forEach((category) => {
            allChoices.push(category);
        });
        Array.from(Object.values(negoceOptions)).forEach((negoce) => {
            allChoices.push(negoce);
        });
        baseProductsChoices.forEach((product) => {
            allChoices.push(product);
        });
        return allChoices;
    }, [products.data]);

    const userOptions: MenuOption[] = useMemo(() => {
        const baseUserChoices: MenuOption[] = [];
        const allUsersIds: number[] = [];
        users.data?.forEach((user) => {
            baseUserChoices.push({
                label: user.email,
                value: user.id,
            });
            allUsersIds.push(user.id);
        });
        const allChoices: MenuOption[] = [];
        allChoices.push({
            label: 'Tous les utilisateurs',
            value: allUsersIds,
            isHelper: true,
        });
        baseUserChoices.forEach((user) => {
            allChoices.push(user);
        });
        return allChoices;
    }, [users.data]);

    const storeOptions: MenuOption[] = useMemo(() => {
        const baseStoreChoices: MenuOption[] = [];
        const allStoresRefs: string[] = [];
        storesData.forEach((store) => {
            baseStoreChoices.push({
                label: store.label,
                value: store.checkpointStoreRef,
            });
            allStoresRefs.push(store.checkpointStoreRef);
        });
        const allChoices: MenuOption[] = [];
        allChoices.push({
            label: 'Toutes les boutiques',
            value: allStoresRefs,
            isHelper: true,
        });
        baseStoreChoices.forEach((store) => {
            allChoices.push(store);
        });
        return allChoices;
    }, [storesData]);

    const menuOptions: MenuOption[] = useMemo(() => {
        const baseMenuChoices: MenuOption[] = [];
        const allMenuIds: string[] = [];
        Object.entries(MENUS).forEach(([key, menu]) => {
            baseMenuChoices.push({
                label: menu.defaultName,
                value: key,
            });
            allMenuIds.push(key);
        });
        const allChoices: MenuOption[] = [];
        allChoices.push({
            label: 'Tous les menus',
            value: allMenuIds,
            isHelper: true,
        });
        baseMenuChoices.forEach((menu) => {
            allChoices.push(menu);
        });
        return allChoices;
    }, []);

    const reductionOptions: MenuOption[] = useMemo(() => {
        if (type === 'Produit' || type === 'Menu') {
            return [
                // TODO : taux promo
                // { label: 'Taux', value: 'Taux' },
                { label: '1 acheté 1 offert', value: 'ONE_FOR_ONE' },
            ];
        }
        // TODO : user promo campaigns
        // if (type === 'Utilisateur') {
        //     return [
        //         { label: 'Taux', value: 'Taux' },
        //         { label: 'BRI', value: 'BRI' },
        //     ];
        // }
        if (type === 'Panier') {
            return [{ label: 'BRI', value: 'BRI' }];
        }
        return [];
    }, [type]);

    const campaignTypeOptions: MenuOption[] = useMemo(() => {
        if (type === 'Produit') {
            return [{ label: 'Code Promo', value: 'private' }];
        }
        if (type === 'Utilisateur' || type === 'Menu') {
            return [{ label: 'Code Promo', value: 'private' }];
        }
        return [];
    }, [type]);

    // Display '%' or '€' depending on the reduction type
    const isRate = watch('reductionType') === 'Taux';

    // ******************************************************************** //
    //                              MUTATIONS                               //
    // ******************************************************************** //

    const { mutate: upload, isLoading: isUploading } = useMutation(createPromoCampaign, {
        onSuccess: navigateBack,
        onError: (error) => {
            showToast({
                severity: 'error',
                message: getErrorMessageWithSentry(error),
            });
        },
    });

    const { mutate: update, isLoading: isUpdating } = useMutation(updatePromoCampaign, {
        onSuccess: navigateBack,
        onError: (error) => {
            showToast({
                severity: 'error',
                message: getErrorMessageWithSentry(error),
            });
        },
    });

    // ******************************************************************** //
    //                               HANDLERS                               //
    // ******************************************************************** //

    const uploadCampaign = async () => {
        const valid = await trigger(); // Trigger form validation

        if (type === 'Produit' && getValues('targetIds').length === 0) {
            showToast({
                severity: 'error',
                message: 'Veuillez sélectionner au moins un produit',
            });
            return;
        }

        if (type === 'Utilisateur' && getValues('targetIds').length === 0) {
            showToast({
                severity: 'error',
                message: 'Veuillez sélectionner au moins un utilisateur',
            });
            return;
        }

        if (type === 'Menu' && getValues('targetIds').length === 0) {
            showToast({
                severity: 'error',
                message: 'Veuillez sélectionner au moins un menu',
            });
            return;
        }

        if (valid) {
            upload(zPromoCampaignForm.parse(getValues()));
        } else {
            showToast({
                severity: 'error',
                message: 'Veuillez remplir tous les champs obligatoires',
            });
        }
    };

    const updateCampaign = async () => {
        const valid = await trigger(); // Trigger form validation

        if (type === 'Produit' && getValues('targetIds').length === 0) {
            showToast({
                severity: 'error',
                message: 'Veuillez sélectionner au moins un produit',
            });
            return;
        }

        if (type === 'Utilisateur' && getValues('targetIds').length === 0) {
            showToast({
                severity: 'error',
                message: 'Veuillez sélectionner au moins un utilisateur',
            });
            return;
        }

        if (type === 'Menu' && getValues('targetIds').length === 0) {
            showToast({
                severity: 'error',
                message: 'Veuillez sélectionner au moins un menu',
            });
            return;
        }

        if (valid) {
            update({
                id: parseInt(id!, 10),
                // Call the zod parser in order to transform strings with French number notation into numbers
                data: zPromoCampaignForm.parse(getValues()),
            });
        }
    };

    // if the user switch campaign type we need to resert targetIds

    useEffect(() => {
        if (data) setValue('targetIds', getTargetIds(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, setValue]);

    useEffect(() => {
        // i dont want to make reductionValue nullable so :
        if (getValues('reductionType') === 'ONE_FOR_ONE') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setValue('reductionValue', '0');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValues, setValue, watch('reductionType')]);
    return (
        <Page sx={{ maxWidth: '50vw', margin: 'auto' }}>
            <Toast />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={navigateBack}>
                    <ArrowBackIcon
                        sx={{
                            color: ThemeColors.BLACK,
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </IconButton>
                <Typography style={{ ...fonts.pageSubtitle, marginLeft: '16px' }} flexGrow={1}>
                    {updateMode
                        ? `${isAdmin ? 'Modifier une campagne' : 'Voir une campagne'}`
                        : 'Ajouter une campagne'}
                </Typography>

                <Button
                    variant="outlined"
                    sx={{ marginRight: '16px', ...ButtonOverrides.blackOutlined }}
                    onClick={navigateBack}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    disabled={isUpdating || isUploading || !isAdmin}
                    onClick={updateMode ? updateCampaign : uploadCampaign}
                >
                    {updateMode ? 'Modifier' : 'Ajouter'}
                </Button>
            </div>
            <LoadingPaper
                sx={{ marginTop: '40px', padding: '24px', paddingBottom: '40px' }}
                loading={isLoading && updateMode}
            >
                <Typography mb="8px" style={fonts.inputHeader}>
                    Nom
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    placeholder="Nom de la campagne"
                    color="info"
                    control={control}
                    trigger={trigger}
                    name="name"
                    fullWidth
                />
                <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                    Type
                </Typography>

                <SelectRhf
                    name="type"
                    options={typeOptions}
                    control={control}
                    sx={{ marginBottom: '16px' }}
                    disableClearable
                />

                {type === 'Utilisateur' && (
                    <Alert severity="error">
                        Ce type de promo n'est pas encore disponible. Veuillez choisir un autre
                        type.
                    </Alert>
                )}

                {type === 'Produit' ? (
                    <>
                        <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                            Produits
                        </Typography>
                        <SelectMultipleRhf
                            options={productOptions}
                            control={control}
                            name="targetIds"
                        />
                    </>
                ) : null}

                {type === 'Utilisateur' ? (
                    <>
                        <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                            Utilisateurs
                        </Typography>
                        <SelectMultipleRhf
                            options={userOptions}
                            control={control}
                            name="targetIds"
                        />
                    </>
                ) : null}

                {type === 'Menu' ? (
                    <>
                        <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                            Menus
                        </Typography>
                        <SelectMultipleRhf
                            options={menuOptions}
                            control={control}
                            name="targetIds"
                        />
                    </>
                ) : null}

                <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                    Boutiques
                </Typography>
                <SelectMultipleRhf
                    disabled
                    options={storeOptions}
                    control={control}
                    name="storeTargetRefs"
                />
                <Alert severity="info">
                    Ettant donné qu'il n'y a qu'une seule boutique pour le moment, la sélection de
                    boutiques n'est pas encore disponible.
                </Alert>

                <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                    Réduction
                </Typography>
                <div style={{ display: 'flex' }}>
                    <RadioGroupRhf
                        options={reductionOptions}
                        control={control}
                        name="reductionType"
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    />
                    <div style={{ flex: 1 }} />
                    {!(getValues('reductionType') === 'ONE_FOR_ONE') && (
                        <TextFieldRhf
                            variant="outlined"
                            control={control}
                            trigger={trigger}
                            name="reductionValue"
                            placeholder={isRate ? '%' : '€'}
                            inputProps={{
                                ...TextOverrides.rightPlaceholder,
                                style: { width: '250px' },
                            }}
                        />
                    )}
                </div>
                <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                    Date d'effet
                </Typography>
                <div style={{ display: 'flex' }}>
                    <DatePickerRhf
                        label="du"
                        control={control}
                        name="startDate"
                        before={watch('endDate')}
                    />
                    <div style={{ width: '16px' }} />
                    <DatePickerRhf
                        label="au"
                        control={control}
                        name="endDate"
                        after={watch('startDate')}
                    />
                </div>
                <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                    Informations
                </Typography>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <RadioGroupRhf
                            options={campaignTypeOptions}
                            control={control}
                            name="campaignType"
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        />
                    </div>
                    <div style={{ width: '16px' }} />
                    {watch('campaignType') === 'private' ? (
                        <div style={{ flex: 1 }}>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="publicCode"
                                placeholder="code"
                                fullWidth
                            />
                        </div>
                    ) : null}
                </div>
            </LoadingPaper>
        </Page>
    );
};

export default AddPromoCampaignPage;
