import { ExcelUploadResponse } from '@gozoki/api';
import {
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { fonts } from '../../utils/theme/fonts.theme';

export interface ProductImportFeedbackProps {
    response: ExcelUploadResponse;
}

/** Display feedback from Excel import results */
const ProductImportFeedback = ({ response }: ProductImportFeedbackProps) => {
    const showErrors =
        response.errors.length > 0 || response.data.failedParsing > 0 || response.data.failed > 0;

    return (
        <>
            <Divider sx={{ marginTop: '36px', marginBottom: '16px' }} />
            <Typography style={fonts.inputHeader} mb="8px">
                Importation
            </Typography>
            <Typography style={fonts.userTag}>
                {updateProductMessage(response.data.updated)}
            </Typography>
            <Typography style={fonts.userTag}>
                {createProductMessage(response.data.created)}
            </Typography>
            {showErrors && (
                <>
                    <Divider sx={{ marginTop: '16px', marginBottom: '16px' }} />
                    <Typography style={fonts.inputHeader} mb="8px">
                        Erreurs
                    </Typography>
                    <Typography style={fonts.userTag}>
                        {excelReadErrorMessage(response.data.failedParsing)}
                    </Typography>
                    <Typography style={fonts.userTag}>
                        {insertProductErrorMessage(response.data.failed)}
                    </Typography>
                    <Divider sx={{ marginTop: '16px', marginBottom: '16px' }} />
                    <Typography style={fonts.inputHeader} mb="8px">
                        Détail des erreurs
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={fonts.inputHeader}>Référence</TableCell>
                                <TableCell align="left" style={fonts.inputHeader}>
                                    Erreur
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {response.errors.map((item, index) => (
                                <TableRow key={`${index}-${item.reference}-${item.errors.join()}`}>
                                    <TableCell style={fonts.userTag}>{item.reference}</TableCell>
                                    <TableCell>
                                        <ul>
                                            {item.errors.map((error, i) => (
                                                <li key={error + i}>{error}</li>
                                            ))}
                                        </ul>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </>
    );
};

export default ProductImportFeedback;

// Helper methods for singular / plural handling
const updateProductMessage = (count: number) => {
    if (count === 1) return '1 produit a été mis à jour';
    return `${count} produits ont été mis à jour`;
};

const createProductMessage = (count: number) => {
    if (count === 1) return '1 produit a été créé';
    return `${count} produits ont été créés`;
};

const excelReadErrorMessage = (count: number) => {
    if (count === 1) return "1 produit n'a pas pu être lu correctement depuis le fichier Excel";
    return `${count} produits n'ont pas pu être lus correctement depuis le fichier Excel`;
};

const insertProductErrorMessage = (count: number) => {
    if (count === 1) return "1 produit n'a pas pu être inséré dans la base de données";
    return `${count} produits n'ont pas pu être insérés dans la base de données`;
};
