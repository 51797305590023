import { CartReportWithTaxes } from '@gozoki/api-types';
import { gozokiApiWithAuth } from '../gozokiApi';

type Res = {
    message: string;
    cartInfos: CartReportWithTaxes;
    failedReferences: { epc: string; reason: string }[];
};

export const testCartQuery = async (userId: number | null, articles: string[]) => {
    const body = new FormData();

    body.append('userId', userId?.toString() || '');
    articles.forEach((article) => body.append('articles[]', article));

    const response = await gozokiApiWithAuth.post<Res>(`/test-cart-compute`, body);

    return response.data;
};
