import { Button, Typography } from '@mui/material';
import {
    downloadPaymentsCsv,
    downloadWorksConcilHistoriesCsv,
    useAccountingKpis,
    useAccountingWarnings,
    usePeriodAccountingKpis,
} from '@gozoki/api';
import { useCallback, useMemo, useState } from 'react';

import { DashboardCard } from '../../utils/dashboard/dashboardCards';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import DownloadIcon from '@mui/icons-material/Download';
import Page from '../../components/Page';
import { fonts } from '../../utils/theme/fonts.theme';
import { frenchFormat } from '@gozoki/tools';
import { useMutation } from '@tanstack/react-query';
import { writeAccountingFiles } from '../../utils/excel/writeAccountingFile';

const yersterday = DateTime.now().minus({ days: 1 });
const aWeekAgo = DateTime.now().minus({ days: 7 });

const AccountingPage = () => {
    const [currentDate, setCurrentDate] = useState<DateTime>(yersterday);

    const [currentStartDate, setCurrentStartDate] = useState<DateTime>(aWeekAgo);
    const [currentEndDate, setCurrentEndDate] = useState<DateTime>(yersterday);

    const [isLoading, setIsLoading] = useState(false);

    const currentFormatedDate = useMemo(() => currentDate.toFormat('yyyy-MM-dd'), [currentDate]);
    const currentFormatedStartDate = useMemo(
        () => currentStartDate.toFormat('yyyy-MM-dd'),
        [currentStartDate]
    );
    const currentFormatedEndDate = useMemo(
        () => currentEndDate.toFormat('yyyy-MM-dd'),
        [currentEndDate]
    );

    const { data: accounting } = useAccountingKpis(currentFormatedDate);
    const { data: periodAccounting } = usePeriodAccountingKpis(
        currentFormatedStartDate,
        currentFormatedEndDate
    );
    const { data: warnings } = useAccountingWarnings();

    const { mutate: downloadAuditFile } = useMutation(downloadPaymentsCsv, {
        onSuccess: (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `payments-${currentFormatedStartDate}-${currentFormatedEndDate}.csv`
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
            setIsLoading(false);
        },
        onError: () => {
            setIsLoading(false);
            console.error('Error while downloading payments file');
        },
    });

    const { mutate: downloadWorksConcilsHistoryFile } = useMutation(
        downloadWorksConcilHistoriesCsv,
        {
            onSuccess: (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `CE-historique-${currentFormatedDate}.csv`);
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
                setIsLoading(false);
            },
            onError: () => {
                setIsLoading(false);
                console.error('Error while downloading works concils histories file');
            },
        }
    );

    const {
        internalNegoceHt,
        internalNegoceTva,
        externalNegoceHt,
        externalNegoceTva,
        sushisTtc,
        amountCb,
        amountConecs,
        unPaidAmount,
        promoAmountHt,
        promoAmountTva,
    } = accounting?.main ?? {
        internalNegoceHt: 0,
        internalNegoceTva: 0,
        externalNegoceHt: 0,
        externalNegoceTva: 0,
        sushisTtc: 0,
        amountCb: 0,
        amountConecs: 0,
        unPaidAmount: 0,
        promoAmountHt: 0,
        promoAmountTva: 0,
    };

    const handleDownload = useCallback(async () => {
        if (!accounting) return;
        setIsLoading(true);
        await writeAccountingFiles([accounting]);
        setIsLoading(false);
    }, [accounting]);

    const handlePeriodDownload = useCallback(async () => {
        setIsLoading(true);
        await writeAccountingFiles(periodAccounting ?? []);
        setIsLoading(false);
    }, [periodAccounting]);

    const handleDownloadPaymentsFile = useCallback(() => {
        setIsLoading(true);
        const startIsoDate = currentStartDate.toISODate();
        const endIsoDate = currentEndDate.toISODate();
        downloadAuditFile({ dateStart: startIsoDate ?? '', dateEnd: endIsoDate ?? '' });
    }, [currentStartDate, currentEndDate, downloadAuditFile]);

    return (
        <Page sx={fonts.roboto}>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 20 }}>
                <Typography style={fonts.pageTitle} flexGrow={1}>
                    Comptabilité
                </Typography>
                <Button
                    style={{ marginTop: 8, marginRight: 40 }}
                    variant="contained"
                    disabled={isLoading}
                    onClick={handleDownload}
                >
                    <DownloadIcon />
                    Exporter le jour sélectionné
                </Button>
                <DatePicker
                    value={currentDate}
                    onChange={(date: DateTime | null) => setCurrentDate(date ?? yersterday)}
                    format="dd/MM/yyyy"
                />
            </div>
            <div style={{ border: '3px solid white', padding: 10 }}>
                {/* <Typography style={fonts.pageSubtitle} flexGrow={1}>
                    Comptabilité principale
                </Typography> */}
                <div style={{ display: 'flex' }}>
                    <DashboardCard
                        label="Vente Négoce interne HT"
                        bigValue={`${frenchFormat(internalNegoceHt)}\u00A0€ HT`}
                        smallValue={`TVA : ${frenchFormat(internalNegoceTva)}\u00A0€`}
                        orange
                    />
                    <DashboardCard
                        label="Vente négoce externe HT"
                        bigValue={`${frenchFormat(externalNegoceHt)}\u00A0€ HT`}
                        smallValue={`TVA : ${frenchFormat(externalNegoceTva)}\u00A0€`}
                    />
                    <DashboardCard
                        label="Vente Sushis TTC"
                        bigValue={`${frenchFormat(sushisTtc)}\u00A0€`}
                    />
                </div>
                <div style={{ display: 'flex' }}>
                    <DashboardCard
                        label="Ventes CB"
                        bigValue={`${frenchFormat(amountCb)}\u00A0€`}
                    />
                    <DashboardCard
                        label="Ventes Conecs"
                        bigValue={`${frenchFormat(amountConecs)}\u00A0€`}
                    />
                    <DashboardCard
                        label="Impayés clients TTC"
                        bigValue={`${frenchFormat(unPaidAmount)}\u00A0€`}
                    />
                    <DashboardCard
                        label="Montant promotions HT"
                        bigValue={`${frenchFormat(promoAmountHt)}\u00A0€`}
                        smallValue={`TVA : ${frenchFormat(promoAmountTva)}\u00A0€`}
                    />
                </div>
            </div>
            <div style={{ padding: 30 }} />
            <div
                style={{
                    border: '3px solid white',
                    padding: 10,
                }}
            >
                <Typography style={fonts.pageSubtitle} flexGrow={1}>
                    Exporter sur une période donnée
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        paddingTop: 20,
                    }}
                >
                    <div style={{ padding: 10 }}>
                        <Typography style={fonts.inputSubtitle} flexGrow={1}>
                            Date de début
                        </Typography>
                        <DatePicker
                            value={currentStartDate}
                            onChange={(date: DateTime | null) =>
                                setCurrentStartDate(date ?? yersterday)
                            }
                            format="dd/MM/yyyy"
                        />
                    </div>
                    <div style={{ padding: 10 }}>
                        <Typography style={fonts.inputSubtitle} flexGrow={1}>
                            Date de fin
                        </Typography>
                        <DatePicker
                            value={currentEndDate}
                            onChange={(date: DateTime | null) =>
                                setCurrentEndDate(date ?? yersterday)
                            }
                            format="dd/MM/yyyy"
                        />
                    </div>
                    <Button
                        style={{ marginTop: 40, marginLeft: 40 }}
                        variant="outlined"
                        color="secondary"
                        disabled={isLoading}
                        onClick={handlePeriodDownload}
                    >
                        <DownloadIcon />
                        Exporter sur le bilan comptable
                    </Button>
                    <Button
                        style={{ marginTop: 40, marginLeft: 40 }}
                        variant="outlined"
                        color="info"
                        onClick={handleDownloadPaymentsFile}
                        disabled={isLoading}
                    >
                        <DownloadIcon />
                        Exporter les paiements
                    </Button>
                    <Button
                        style={{ marginTop: 40, marginLeft: 40 }}
                        variant="outlined"
                        color="info"
                        onClick={() => downloadWorksConcilsHistoryFile(currentFormatedDate)}
                        disabled={isLoading}
                    >
                        <DownloadIcon />
                        Exporter les historiques de CE
                    </Button>
                </div>
            </div>
            <div
                style={{
                    border: '3px solid white',
                    padding: 10,
                    marginTop: 20,
                }}
            >
                <Typography style={fonts.pageSubtitle} flexGrow={1}>
                    Avertissements
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
                    {warnings?.map((warning) => (
                        <Typography style={fonts.inputSubtitle} key={warning.date}>
                            {warning.date} : différence de&nbsp;&nbsp;
                            {frenchFormat(warning.difference)}€
                        </Typography>
                    ))}
                </div>
            </div>
        </Page>
    );
};

export default AccountingPage;
