import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { Category, MENU_TYPES_OPTIONS } from '@gozoki/api-types';
import { PRODUCTS_ROUTES, ROUTES, ROUTE_BUILDER } from '../../utils/constants/routes.constants';
import { useBrands, useCategories } from '@gozoki/api';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ButtonOverrides } from '../../utils/theme/overrides.theme';
import LoadingPaper from '../../components/containers/LoadingPaper';
import { MenuOption } from '../../components/containers/MenuOptions';
import Page from '../../components/Page';
import { ProductOutletContext } from './ProductsOutletProvider';
import SelectMultipleRhf from '../../components/inputs/SelectMultipleRhf';
import SelectRhf from '../../components/inputs/SelectRhf';
import Text from '../../components/communication/Text';
import TextFieldRhf from '../../components/inputs/TextFieldRhf';
import { ThemeColors } from '../../utils/theme/colors.theme';
import { fonts } from '../../utils/theme/fonts.theme';
import { useRights } from '../../utils/hooks';

const getCategoryLabel = (category: Category) => {
    if (category.isSubCategory) {
        return `${category.name} (sous-catégorie)`;
    }

    return category.name;
};

/*
 * There are a lot of commented code in this file because of the weight/price feature
 * We decided to remove it for now to stay focused on the main features
 * But we might add it back later
 */

// const priceModeOptions: MenuOption[] = [
//     { label: 'Fixe', value: 'fixed' },
//     { label: 'Poids/kg', value: 'ratio' },
// ];

const AddProductPage1 = () => {
    const navigate = useNavigate();
    const isAccountingAdmin = useRights(['ACCOUNTING_ADMIN']);

    const { productForm, updateMode, productId, isUpdateModeLoading } =
        useOutletContext<ProductOutletContext>();
    const { control, trigger, reset, setValue, watch } = productForm;

    const [isFixedPriceMode, setIsFixedPriceMode] = useState(false);

    // Set the correct price mode when the form has finished loading
    // Because of the intricate form & data structure, this must be done like this
    const priceMode = watch('priceMode');
    useEffect(() => {
        setIsFixedPriceMode(priceMode === 'fixed');
    }, [isUpdateModeLoading, priceMode]);

    // const updatePriceModeCallback = (value: unknown) => {
    //     const fixedPriceMode = value === 'fixed';

    //     setIsFixedPriceMode(fixedPriceMode);

    //     if (fixedPriceMode) {
    //         // Ca marche mais ils ne se mettent pas à jour. Watch ?
    //         setValue('weightPrice', null);
    //         setValue('sellWeightPrice', null);
    //     }
    //     if (!fixedPriceMode) {
    //         setValue('price', null);
    //         setValue('sellPrice', null);
    //     }
    // };

    const categories = useCategories();
    const brands = useBrands();

    const cancel = () => {
        reset();
        navigate(ROUTES.PRODUCTS);
    };
    const navigateBack = cancel; // Because this is the first page, navigating back should also cancel
    const navigateNext = async () => {
        // Validate this page before going to the next one
        const valid = await trigger([
            'reference',
            'label',
            isFixedPriceMode ? 'price' : 'weightPrice', // Only validate the relevant field
            'weight',
            isFixedPriceMode ? 'sellPrice' : 'sellWeightPrice', // Only validate the relevant field
            'colisage',
        ]);

        // Force nullify for the fields that are not relevant
        if (isFixedPriceMode) {
            setValue('weightPrice', null);
            setValue('sellWeightPrice', null);
        }
        if (!isFixedPriceMode) {
            setValue('price', null);
            setValue('sellPrice', null);
        }

        if (valid && !updateMode) navigate(PRODUCTS_ROUTES.ADD_PRODUCT_SECOND_STEP);
        if (valid && updateMode) navigate(ROUTE_BUILDER.updateProductSecondStep(productId));
    };

    // ****************************************************** //
    //            QUERY TO OPTIONS TRANSFORMATION             //
    // ****************************************************** //

    const categoryOptions: MenuOption[] = useMemo(
        () =>
            categories.data?.map((category) => ({
                label: getCategoryLabel(category),
                value: category.id,
            })) ?? [],
        [categories.data]
    );

    const brandOptions: MenuOption[] = useMemo(
        () => brands.data?.map((brand) => ({ label: brand.name, value: brand.id })) ?? [],
        [brands.data]
    );

    return (
        <Page sx={{ maxWidth: '50vw', margin: 'auto' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={navigateBack}>
                    <ArrowBackIcon
                        sx={{
                            color: ThemeColors.BLACK,
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </IconButton>
                <Typography style={{ ...fonts.pageSubtitle, marginLeft: '16px' }} flexGrow={1}>
                    {updateMode ? 'Modifier un produit' : 'Ajouter un produit'}
                </Typography>

                <Button
                    variant="outlined"
                    sx={{ marginRight: '16px', ...ButtonOverrides.blackOutlined }}
                    onClick={cancel}
                >
                    Annuler
                </Button>
                <Button variant="contained" color="secondary" onClick={navigateNext}>
                    Suivant
                </Button>
            </div>
            <LoadingPaper
                sx={{ marginTop: '40px', padding: '24px', paddingBottom: '40px' }}
                loading={isUpdateModeLoading && updateMode}
            >
                <Typography mb="8px" style={fonts.inputHeader}>
                    Référence (EAN 13)
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    control={control}
                    trigger={trigger}
                    name="reference"
                    placeholder="Référence"
                    fullWidth
                    disabled={updateMode} // Cannot update the reference
                />

                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Libellé
                </Typography>
                <TextFieldRhf
                    variant="outlined"
                    control={control}
                    trigger={trigger}
                    name="label"
                    placeholder="Nom du produit"
                    fullWidth
                />
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={4}>
                        <Grid item flex={1}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                Catégorie(s)
                            </Typography>
                            <SelectMultipleRhf
                                control={control}
                                name="categories"
                                options={categoryOptions}
                            />
                        </Grid>
                        <Grid item flex={1}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                Marque
                            </Typography>
                            <SelectRhf
                                options={brandOptions}
                                control={control}
                                name="brandId"
                                disableClearable
                                required
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={4}>
                        <Grid item flex={1}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                Eligible aux menus ?
                            </Typography>
                            <SelectRhf
                                options={MENU_TYPES_OPTIONS as MenuOption[]}
                                control={control}
                                name="menuType"
                            />
                        </Grid>
                        <Grid item flex={1}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                Supplément menu (€) ?
                            </Typography>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                fullWidth
                                name="menuExtraPrice"
                            />
                        </Grid>
                        <Grid item flex={1}>
                            <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                                Portions
                            </Typography>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="portions"
                                placeholder="e.g. 2-3 pers."
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Prix
                </Typography>
                {/* <RadioGroupRhf
                    control={control}
                    name="priceMode"
                    options={priceModeOptions}
                    row
                    onChangeCallback={updatePriceModeCallback}
                /> */}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={4}>
                        <Grid item flex={1}>
                            <Text mt="24px" mb="8px" style={fonts.inputSubtitle}>
                                Poids (g)
                            </Text>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="weight"
                                placeholder="0.00 g"
                                fullWidth
                            />
                        </Grid>
                        <Grid
                            item
                            flex={1}
                            style={{ display: isFixedPriceMode ? 'none' : undefined }}
                        >
                            <Text mt="24px" mb="8px" style={fonts.inputSubtitle}>
                                Prix d'achat au kilo
                            </Text>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="weightPrice"
                                placeholder="0.00 €/kg"
                                fullWidth
                                disabled={!isAccountingAdmin}
                            />
                        </Grid>
                        <Grid
                            item
                            flex={1}
                            style={{ display: !isFixedPriceMode ? 'none' : undefined }}
                        >
                            <Text mt="24px" mb="8px" style={fonts.inputSubtitle}>
                                Prix d'achat à unité
                            </Text>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="price"
                                placeholder="0.00"
                                fullWidth
                                disabled={!isAccountingAdmin}
                            />
                        </Grid>
                        <Grid item flex={1}>
                            <Text mt="24px" mb="8px" style={fonts.inputSubtitle}>
                                Prix de vente à l'unité
                            </Text>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="sellPrice"
                                placeholder="0.00"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Colisage
                </Typography>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={4}>
                        <Grid item flex={1}>
                            <Text mt="24px" mb="8px" style={fonts.inputSubtitle}>
                                Colisage
                            </Text>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="colisage"
                                fullWidth
                            />
                        </Grid>
                        <Grid item flex={1}>
                            <Text mt="24px" mb="8px" style={fonts.inputSubtitle}>
                                Poids du colis (grammes)
                            </Text>
                            <TextFieldRhf
                                variant="outlined"
                                control={control}
                                trigger={trigger}
                                name="colisWeight"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Box>
            </LoadingPaper>
        </Page>
    );
};

export default AddProductPage1;
