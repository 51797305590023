import { FormControl, FormControlLabel, Radio, RadioGroup, RadioGroupProps } from '@mui/material';
import { MenuOption } from '../containers/MenuOptions';
import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';

export interface RadioGroupRhfProps<T extends FieldValues> extends RadioGroupProps {
    options: MenuOption[];
    control: Control<T>; // Controller attribute from the form
    name: Path<T>; // Field name from the form
    onChangeCallback?: (value: PathValue<T, Path<T>>) => void;
}

// Default value does not work ? Debug with log

const RadioGroupRhf = <T extends FieldValues>({
    options,
    control,
    name,
    onChangeCallback,
    ...radioGroupProps
}: RadioGroupRhfProps<T>) => {
    const {
        field: { value, onChange, onBlur: rhfOnBlur },
    } = useController({
        name,
        control,
    });

    return (
        <FormControl>
            <RadioGroup
                onChange={(e) => {
                    onChange(e.target.value as PathValue<T, Path<T>>);
                    onChangeCallback?.(e.target.value as PathValue<T, Path<T>>);
                }}
                value={value ?? 0}
                {...radioGroupProps}
                onBlur={rhfOnBlur}
            >
                {options.map((option) => (
                    <FormControlLabel
                        key={`${option.value}`}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioGroupRhf;
