import {
    AccountingKpis,
    DailyDashboardKpi,
    DailyStockKpi,
    HoursData,
    TimeSpentData,
} from '@gozoki/api-types';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
    fetchAccountingKpis,
    fetchAccountingWarnings,
    fetchAllDailysKpis,
    fetchAllStockKpis,
    fetchHoursData,
    fetchPeriodAccountingKpis,
    fetchTimeSpentData,
} from '../queries/kpi.queries';

export const useStoresKpis = (): UseQueryResult<DailyDashboardKpi[], unknown> => {
    // if storesIDs is undefined, we query all stores
    return useQuery(['get-kpi'], () => {
        return fetchAllDailysKpis();
    });
};

export const useStockKpis = (): UseQueryResult<DailyStockKpi[], unknown> => {
    return useQuery(['get-stock-kpi'], () => {
        return fetchAllStockKpis();
    });
};

export const useAccountingKpis = (date: string): UseQueryResult<AccountingKpis, unknown> => {
    return useQuery(['get-accounting-kpis', date], () => {
        return fetchAccountingKpis(date);
    });
};

export const useAccountingWarnings = (): UseQueryResult<
    { date: string; difference: number }[],
    unknown
> => {
    return useQuery(['get-accounting-warnings'], () => {
        return fetchAccountingWarnings();
    });
};

export const usePeriodAccountingKpis = (
    dateStart: string,
    dateEnd: string
): UseQueryResult<AccountingKpis[], unknown> => {
    return useQuery(['get-period-accounting-kpis', dateStart, dateEnd], () => {
        return fetchPeriodAccountingKpis(dateStart, dateEnd);
    });
};

export const useHoursData = (currentMode: string): UseQueryResult<HoursData[], unknown> => {
    return useQuery(['get-hours-data', currentMode], () => {
        return fetchHoursData(currentMode === 'all' ? null : currentMode);
    });
};

export const useTimeSpentDatas = (
    currentMode: string
): UseQueryResult<TimeSpentData[], unknown> => {
    return useQuery(['get-time-spent-data', currentMode], () => {
        return fetchTimeSpentData(currentMode === 'all' ? null : currentMode);
    });
};
