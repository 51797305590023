import {
    Button,
    Chip,
    Dialog,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import { ButtonOverrides, DataGridOverrides } from '../../utils/theme/overrides.theme';
import { CategoryTabState, State } from '../../utils/types/state.types';
import { ROUTES, ROUTE_BUILDER } from '../../utils/constants/routes.constants';
import { StockEntry, UniqueArticle } from '@gozoki/api-types';
import {
    cleanMultipleArticles,
    cleanStoreArticles,
    createLogisticFile,
    destroyStore,
    downloadLogisticReport,
    downloadStockAuditCsv,
    fetchStoreCommandNumber,
    mooveArticlesBetweenStores,
    useStore,
    useStoreProductArticles,
    useStoreStock,
} from '@gozoki/api';
import { frenchFormat, includesLowerCase, priceToString } from '@gozoki/tools';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ConfirmButton } from '../../components/communication/ConfirmButton';
import { ConfirmResourceDeletionButton } from '../../components/communication/ConfirmDeletionButton';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import DivTabPanel from '../../components/containers/DivTabPanel';
import DownloadIcon from '@mui/icons-material/Download';
import { GridColDef } from '@mui/x-data-grid';
import LoadingPaper from '../../components/containers/LoadingPaper';
import { LogisticFileForm } from '@gozoki/api/src/forms/useLogisticFileForm';
import Page from '../../components/Page';
import SimpleDataGrid from '../../components/containers/SimpleDataGrid';
import StockCard from '../../components/communication/StockCard';
import StoreCSVExportButton from '../../components/buttons/StoreCSVExportButton';
import { StoresPicker } from '../../utils/dashboard/storesPicker';
import TabPanel from '../../components/containers/TabPanel';
import { ThemeColors } from '../../utils/theme/colors.theme';
import Thumbnail from '../../components/icons/Thumbnail';
import { captureException } from '@sentry/react';
import { fonts } from '../../utils/theme/fonts.theme';
import { generatePDF } from '../../utils/pdf/writeCommandPdfFile';
import { useMutation } from '@tanstack/react-query';
import { useRights } from '../../utils/hooks';
import { useToast } from '../../components/communication/Toast';

export interface CommandModeChoice {
    value: 'last' | 'none' | 'custom';
    title: string;
}

const lastCommandModeChoices = [
    {
        value: 'last',
        title: 'Relier au dernier bon de commande généré',
    },
    {
        value: 'none',
        title: 'Ne pas relier à un bon de commande, la livraison ne sera pas enregistrée',
    },
    {
        value: 'custom',
        title: 'Utiliser un autre bon de commande',
    },
];

const stockColDef: GridColDef[] = [
    {
        field: 'imageUrl',
        headerName: 'Image',
        headerAlign: 'center', // Align the header text to center
        width: 80,
        renderCell: (field) => <Thumbnail url={field.value} alt="product image" size="60px" />,
    },
    {
        field: 'reference',
        headerName: 'Réf',
        width: 100,
    },
    {
        field: 'label',
        headerName: 'Libellé',
        width: 150,
    },
    {
        field: 'stock',
        headerName: 'Stock',
        width: 50,
    },
    {
        field: 'agStock',
        headerName: 'Stock AG',
        width: 50,
    },
    {
        field: 'expiredStock',
        headerName: 'Stock expiré',
        width: 50,
    },
    {
        field: 'minimumStock',
        width: 70,
        headerName: 'Stock minimum',
    },
    {
        field: 'weekEndMinimumStock',
        headerName: 'Stock minimum WE',
        width: 70,
    },
    {
        field: 'buyingPrice',
        headerName: "Prix d'achat",
        width: 100,
    },
    {
        field: 'colisage',
        headerName: 'Colisage',
        width: 100,
    },
    {
        field: 'totalValueAntiGaspi',
        headerName: 'Valorisation anti gaspi totale',
        renderCell: (field) => (field.value === 0 ? '' : `${frenchFormat(field.value)}\u00A0€`),
        width: 130,
    },
    {
        field: 'totalValue',
        headerName: 'Valorisation totale',
        renderCell: (field) => (field.value === 0 ? '' : `${frenchFormat(field.value)}\u00A0€`),
        width: 100,
    },
    {
        field: 'articlesReaproNeeds',
        headerName: 'Besoin de réassort (articles)',
        renderCell: (field) => (field.value === 0 ? '' : field.value),
        width: 100,
    },
    {
        field: 'reaproNeeds',
        headerName: 'Besoin de réassort (colis)',
        renderCell: (field) => (field.value === 0 ? '' : field.value),
        width: 100,
    },
    {
        field: 'weekEndArticlesReaproNeeds',
        headerName: 'Besoin de réassort WE (articles)',
        renderCell: (field) => (field.value === 0 ? '' : field.value),
        width: 100,
    },
    {
        field: 'weekEndReaproNeeds',
        headerName: 'Besoin de réassort WE (colis)',
        renderCell: (field) => (field.value === 0 ? '' : field.value),
        width: 100,
    },
];

type ArticleState = 'antigaspi' | 'normal' | 'expired';

const getArticleState = (article: UniqueArticle): ArticleState => {
    if (article.isExpired) return 'expired';
    if (article.isAntiGaspi) return 'antigaspi';

    return 'normal';
};

const ARTICLE_STATE_LABEL: { [key in ArticleState]: string } = {
    antigaspi: 'Anti-gaspi',
    expired: 'Expiré',
    normal: 'Normal',
};

type FilterArticleState = ArticleState | 'all';

const articleStateFilter = (article: UniqueArticle, state: FilterArticleState) => {
    if (state === 'all') return true;

    return getArticleState(article) === state;
};

const getChipCss = (state: ArticleState) => {
    const chipCss = {
        color: ThemeColors.WHITE,
        backgroundColor: ThemeColors.NORMAL_GREEN as string,
    };

    if (state === 'expired') chipCss.backgroundColor = ThemeColors.RED;

    if (state === 'antigaspi') chipCss.backgroundColor = ThemeColors.ANTI_GASPI_ORANGE;

    return chipCss;
};

const articlesColDef: GridColDef[] = [
    {
        field: 'reference',
        headerName: 'Réf',
        width: 180,
    },
    {
        field: 'label',
        headerName: 'Libellé',
        flex: 1,
    },
    {
        field: 'dlc',
        headerName: 'DLC',
        flex: 1,
    },
    {
        field: 'state',
        headerName: 'Etat',
        renderCell: (field) => (
            <Chip
                label={ARTICLE_STATE_LABEL[field.value as ArticleState]}
                sx={getChipCss(field.value)}
            />
        ),
        flex: 1,
    },
];

const productLabelFromStockEntries = (stockEntries: StockEntry[], id: number) => {
    const stockEntry = stockEntries.find((entry) => entry.id === id);
    if (stockEntry === undefined) return '';

    return stockEntry.label;
};

const StoreDetailsPage = () => {
    const navigate = useNavigate();
    const [Toast, showToast] = useToast();

    const { id } = useParams();
    const isStockAdmin = useRights(['STOCK_ADMIN']);

    const storeId = useMemo(() => parseInt(id ?? '', 10), [id]);

    const { data, isLoading } = useStore(storeId);

    const { state }: State<CategoryTabState> = useLocation();
    const [currentTab, setCurrentTab] = useState(state?.currentTab ?? 0);
    const [modalMode, setModalMode] = useState<'command' | 'moove' | 'shippingAudit' | undefined>(
        undefined
    );

    // ****************************************************** //
    //                      STOCK VARIABLES                   //
    // ****************************************************** //

    const [stockSearch, setStockSearch] = useState('');
    const [selectedProduct, setSelectedProduct] = useState<number | undefined>(undefined);
    const [selectedArticles, setSelectedArticles] = useState<number[]>([]);

    const [articleState, setArticleState] = useState<FilterArticleState>('all');

    // ****************************************************** //
    //                          QUERIES                       //
    // ****************************************************** //

    const stock = useStoreStock(storeId);
    const articles = useStoreProductArticles(storeId, selectedProduct ?? Number.NaN);

    // ****************************************************** //
    //           QUERY TO DATAGRID TRANSFORMATION             //
    // ****************************************************** //

    const stockRows = useMemo(() => {
        if (stock.data === undefined) {
            return [];
        }
        return stock.data.rows.filter((row) => includesLowerCase(row.label, stockSearch));
    }, [stock.data, stockSearch]);

    const articlesRows = useMemo(() => {
        if (articles.data === undefined) {
            return [];
        }
        return articles.data

            .filter((a) => articleStateFilter(a, articleState))

            .map((article) => ({
                id: article.id,
                reference: article.product.reference,
                label: article.product.label,
                dlc: article.displayedDlc,
                state: getArticleState(article),
            }));
    }, [articles.data, articleState]);

    // ****************************************************** //
    //                         MUTATIONS                      //
    // ****************************************************** //

    const deleteStoreMut = useMutation(destroyStore, {
        onSuccess: () => {
            navigate(ROUTES.STORES);
        },
    });

    const cleanStoreArticlesMut = useMutation(cleanStoreArticles, {
        onSuccess: () => {
            stock.refetch();
        },
    });

    const cleanArticlesMut = useMutation(cleanMultipleArticles, {
        onSuccess: () => {
            stock.refetch();
            setSelectedArticles([]);
            setSelectedProduct(undefined);
        },
    });

    // ****************************************************** //
    //                         HANDLERS                       //
    // ****************************************************** //

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    const navigateBack = () => {
        if (selectedProduct !== undefined) setSelectedProduct(undefined);
        else navigate(ROUTES.STORES);
    };
    const updateStore = () => navigate(`${ROUTES.STORES}/${ROUTE_BUILDER.updateStore(storeId)}`);

    // ****************************************************** //
    //                          BDL                           //
    // ****************************************************** //

    const today = DateTime.now();
    const twoDaysLater = today.plus({ days: 2 });

    const [showModal, setShowModal] = useState(false);

    const [dateDepart, setDateDepart] = useState<DateTime>(today);
    const [dateLivraison, setDateLivraison] = useState<DateTime>(twoDaysLater);

    const [isLoadingBdl, setIsLoadingBdl] = useState(false);

    const [currentReceivingStore, setCurrentReceivingStore] = useState<number>(3);
    const [currentShippingStore, setCurrentShippingStore] = useState<number>(4);

    const { data: receivingStore } = useStore(currentReceivingStore);
    const { data: shippingStore } = useStore(currentShippingStore);

    const [linkableCommandReference, setLinkableCommandReference] = useState<string | null>(null);
    const [lastCommandMode, setLastCommandMode] = useState<CommandModeChoice['value']>('last');

    const { mutateAsync: generateLigisticFile } = useMutation(createLogisticFile, {
        onSuccess: () => {
            showToast({
                message: 'Bon de livraison généré',
                severity: 'success',
            });
        },
        onError: (err) => {
            captureException(err);
            showToast({
                message: 'Erreur lors de la génération du bon de livraison',
                severity: 'error',
            });
        },
    });

    const handleGenerateCommand = useCallback(async () => {
        const reaproNeeds = stockRows.filter((row) => row.reaproNeeds > 0);
        if (!data) return;
        setIsLoadingBdl(true);
        const commandNumber = await fetchStoreCommandNumber(data.id);
        const commandReference = `${'BCL'}-${data.id}-${commandNumber}`;
        const lines = reaproNeeds.map((row) => ({
            id: row.id,
            quantity: row.reaproNeeds * row.colisage,
        }));

        const logisticFileData: LogisticFileForm = {
            reference: commandReference,
            logisticFileType: 'command',
            shippingDate: today.toISODate() ?? '',
            receivingDate: dateLivraison.toISODate() ?? '',
            shippingStoreId: null,
            receivingStoreId: data.id,
            commandReference: null,
            lines,
            useLastCommand: false,
        };

        await generateLigisticFile(logisticFileData)
            .then(async () => {
                await generatePDF(
                    reaproNeeds,
                    data,
                    undefined,
                    today,
                    dateLivraison,
                    'command',
                    commandReference
                );
                setIsLoadingBdl(false);
                setShowModal(false);
            })
            .catch(() => {
                setIsLoadingBdl(false);
            });
    }, [data, dateLivraison, generateLigisticFile, stockRows, today]);

    const handleGenerateDelivery = useCallback(async () => {
        const reaproNeeds = stockRows.filter((row) => row.stock > 0);
        if (!data) return;
        setIsLoadingBdl(true);
        const commandNumber = await fetchStoreCommandNumber(receivingStore?.id ?? data.id);
        const commandReference = `${'BDL'}-${data.id}-${commandNumber}`;
        const lines = reaproNeeds.map((row) => ({
            id: row.id,
            quantity: row.stock,
        }));

        const logisticFileData: LogisticFileForm = {
            reference: commandReference,
            logisticFileType: 'shipping',
            shippingDate: dateDepart.toISODate() ?? '',
            receivingDate: dateLivraison.toISODate() ?? '',
            shippingStoreId: shippingStore?.id ?? null,
            receivingStoreId: receivingStore?.id ?? data.id,
            commandReference: lastCommandMode === 'custom' ? linkableCommandReference : null,
            lines,
            useLastCommand: lastCommandMode === 'last',
        };

        await generateLigisticFile(logisticFileData)
            .then(async () => {
                await generatePDF(
                    reaproNeeds,
                    receivingStore ?? data,
                    shippingStore ?? undefined,
                    today,
                    dateLivraison,
                    'delivery',
                    commandReference
                );
                setIsLoadingBdl(false);
                setShowModal(false);
            })
            .catch(() => {
                setIsLoadingBdl(false);
            });
    }, [
        data,
        dateDepart,
        dateLivraison,
        generateLigisticFile,
        receivingStore,
        shippingStore,
        stockRows,
        today,
        lastCommandMode,
        linkableCommandReference,
    ]);

    // ****************************************************** //
    //                       STOCK AUDIT                      //
    // ****************************************************** //

    const { mutate: downloadAuditFile, isLoading: loadingAudit } = useMutation(
        downloadStockAuditCsv,
        {
            onSuccess: (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'stock-audit.csv');
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            },
            onError: (err) => {
                console.error(err);
                captureException(err);
                showToast({
                    message: 'Erreur lors du téléchargement du fichier',
                    severity: 'error',
                });
            },
        }
    );

    const handleDownloadAuditFile = useCallback(() => {
        downloadAuditFile(storeId);
    }, [downloadAuditFile, storeId]);

    // ****************************************************** //
    //                      Moove articles                    //
    // ****************************************************** //

    const { mutate: mooveArticles } = useMutation(mooveArticlesBetweenStores, {
        onSuccess: async () => {
            await stock.refetch();
            setModalMode(undefined);
            setShowModal(false);
        },
        onError: (error) => {
            captureException(error);
            showToast({
                message: 'Erreur lors du déplacement des articles',
                severity: 'error',
            });
        },
    });

    // ****************************************************** //
    //                SHIPPING QUALITY AUDIT                  //
    // ****************************************************** //

    const handleSetQualityAuditModal = useCallback(() => {
        const sevenDaysAgo = today.minus({ days: 7 });
        setDateDepart(sevenDaysAgo);
        setDateLivraison(today);
        setModalMode('shippingAudit');
        setShowModal(true);
    }, [today]);

    const { mutate: downloadShippingAuditFile } = useMutation(downloadLogisticReport, {
        onSuccess: (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'audit-livraison.csv');
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
            setShowModal(false);
        },
    });

    const handleDownloadShippingAuditFile = useCallback(() => {
        if (dateDepart.isValid && dateLivraison.isValid) {
            downloadShippingAuditFile({
                dateStart: dateDepart.toISODate() ?? '',
                dateEnd: dateLivraison.toISODate() ?? '',
                storeId: data?.id ?? 3,
            });
        }
    }, [data?.id, dateDepart, dateLivraison, downloadShippingAuditFile]);

    // ****************************************************** //
    //                  GLOBAL VALIDATE                       //
    // ****************************************************** //

    const handleMooveOrDelete = useCallback(() => {
        if (modalMode === 'command') {
            if (data?.storeType === 'BOUTIQUE') {
                handleGenerateCommand();
            } else {
                handleGenerateDelivery();
            }
        } else if (modalMode === 'moove') {
            if (!selectedProduct) {
                mooveArticles({
                    originId: storeId,
                    destinationId: currentReceivingStore,
                    allArticles: true,
                });
            } else if (selectedArticles.length > 0) {
                mooveArticles({
                    originId: storeId,
                    destinationId: currentReceivingStore,
                    articlesIds: selectedArticles,
                });
            } else {
                showToast({
                    message: 'Aucun article sélectionné',
                    severity: 'error',
                });
            }
        } else if (modalMode === 'shippingAudit') {
            handleDownloadShippingAuditFile();
        }
    }, [
        currentReceivingStore,
        data?.storeType,
        handleDownloadShippingAuditFile,
        handleGenerateCommand,
        handleGenerateDelivery,
        modalMode,
        mooveArticles,
        selectedArticles,
        selectedProduct,
        showToast,
        storeId,
    ]);

    return (
        <>
            <Toast />
            <Dialog open={showModal} onClose={() => setShowModal(false)}>
                <div
                    style={{
                        minWidth: 500,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {modalMode ? (
                        <Typography
                            style={fonts.pageSubtitle}
                            sx={{ textAlign: 'center', marginBottom: 2, marginTop: 2 }}
                        >
                            {modalMode === 'command'
                                ? `Générer un bon de ${
                                      data?.storeType === 'BOUTIQUE' ? 'commande' : 'livraison'
                                  }`
                                : `Sélectionner ${
                                      modalMode === 'shippingAudit' ? 'les dates' : 'la destination'
                                  }`}
                        </Typography>
                    ) : null}
                    {(modalMode === 'command' && data?.storeType === 'SHIPPING') ||
                    modalMode === 'shippingAudit' ? (
                        <div style={{ padding: 20, minWidth: 310 }}>
                            <Typography sx={{ paddingBottom: 1 }}>
                                {modalMode === 'shippingAudit' ? 'Date de début' : 'Date de départ'}
                            </Typography>
                            <DatePicker
                                value={dateDepart}
                                onChange={(date: DateTime | null) => setDateDepart(date ?? today)}
                                format="dd/MM/yyyy"
                            />
                        </div>
                    ) : null}
                    {modalMode === 'command' || modalMode === 'shippingAudit' ? (
                        <div style={{ padding: 20, minWidth: 310 }}>
                            <Typography sx={{ paddingBottom: 1 }}>
                                {modalMode === 'shippingAudit'
                                    ? 'Date de fin'
                                    : `Date de Livraison
                                ${data?.storeType === 'BOUTIQUE' ? '  souhaitée' : ''}`}
                            </Typography>
                            <DatePicker
                                value={dateLivraison}
                                onChange={(date: DateTime | null) =>
                                    setDateLivraison(date ?? today)
                                }
                                format="dd/MM/yyyy"
                            />
                        </div>
                    ) : null}
                    {modalMode === 'command' && data?.storeType === 'SHIPPING' ? (
                        <div style={{ padding: 20, minWidth: 310 }}>
                            <Typography sx={{ paddingBottom: 2 }}>Plateforme de départ</Typography>
                            <StoresPicker onlyPlatforms setUniqueStore={setCurrentShippingStore} />
                        </div>
                    ) : null}
                    {modalMode === 'moove' ||
                    (modalMode === 'command' && data?.storeType === 'SHIPPING') ? (
                        <div style={{ padding: 20, minWidth: 310 }}>
                            <Typography sx={{ paddingBottom: 2 }}>
                                {modalMode === 'command' ? 'Boutique à livrer' : 'Destination'}
                            </Typography>
                            <StoresPicker
                                onlyBoutiques={modalMode === 'command'}
                                setUniqueStore={setCurrentReceivingStore}
                            />
                        </div>
                    ) : null}
                    {modalMode === 'command' && data?.storeType === 'SHIPPING' ? (
                        <div style={{ padding: 20 }}>
                            <FormControl fullWidth>
                                <InputLabel>Bon de commande associé</InputLabel>
                                <Select
                                    label="Bon de commande associé"
                                    defaultValue={lastCommandModeChoices[0].value}
                                    onChange={(e) => {
                                        setLastCommandMode(
                                            e.target.value as CommandModeChoice['value']
                                        );
                                    }}
                                    style={{ minWidth: 300 }}
                                >
                                    {lastCommandModeChoices?.map((commandChoice) => (
                                        <MenuItem
                                            key={commandChoice.value}
                                            value={commandChoice.value}
                                        >
                                            {commandChoice.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    ) : null}

                    {modalMode === 'command' &&
                    data?.storeType === 'SHIPPING' &&
                    lastCommandMode === 'custom' ? (
                        <div style={{ padding: 20 }}>
                            <Typography>Numéro du bon de commande</Typography>
                            <TextField
                                onChange={(e) => {
                                    setLinkableCommandReference(e.target.value);
                                }}
                                value={linkableCommandReference}
                            />
                        </div>
                    ) : null}

                    <div style={{ padding: 20 }}>
                        <Button
                            variant="contained"
                            style={{ minWidth: 300 }}
                            onClick={handleMooveOrDelete}
                            disabled={isLoadingBdl}
                        >
                            Valider
                        </Button>
                    </div>
                </div>
            </Dialog>
            <Page>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={navigateBack}>
                        <ArrowBackIcon
                            sx={{
                                color: ThemeColors.BLACK,
                                height: '24px',
                                width: '24px',
                            }}
                        />
                    </IconButton>
                    <Typography
                        style={{ ...fonts.pageSubtitle, marginLeft: '16px', marginBottom: '16px' }}
                        flexGrow={1}
                    >
                        {`${data?.label}${
                            selectedProduct === undefined
                                ? ''
                                : ` / ${productLabelFromStockEntries(stockRows, selectedProduct)}`
                        }`}
                    </Typography>
                    {currentTab === 1 && (
                        <>
                            <ConfirmResourceDeletionButton
                                handleResourceDeletion={() => deleteStoreMut.mutateAsync(storeId)}
                                disabled={deleteStoreMut.isLoading}
                            />
                            <Button variant="contained" onClick={updateStore}>
                                Modifier
                            </Button>
                        </>
                    )}
                </div>

                <div style={{ display: 'flex' }}>
                    <Tabs value={currentTab} onChange={handleChange} sx={{ marginBottom: '16px' }}>
                        <Tab label="Stocks" />
                        <Tab label="Informations" />
                    </Tabs>
                    <div style={{ flex: 1 }} />

                    {currentTab === 0 &&
                    selectedArticles.length > 0 &&
                    isStockAdmin &&
                    data?.storeType !== 'SHIPPING' ? (
                        <>
                            <ConfirmButton
                                buttonText="Supprimer les articles sélectionnés"
                                buttonProps={{
                                    variant: 'outlined',
                                    sx: {
                                        marginRight: '16px',
                                        height: '42px',
                                        ...ButtonOverrides.redOutlined,
                                    },
                                    disabled: cleanStoreArticlesMut.isLoading,
                                }}
                                description="Etes-vous sur de vouloir supprimer les articles sélectionnés ? Cette action est irréversible et bloquera toutes les ventes jusqu'au prochain inventaire"
                                onConfirm={() => {
                                    return cleanArticlesMut.mutateAsync(selectedArticles);
                                }}
                                title="Supprimer tous les articles ?"
                                confirmButton={
                                    cleanStoreArticlesMut.isLoading
                                        ? {
                                              loading: true,
                                              text: 'Suppression en cours     ',
                                          }
                                        : { text: 'Supprimer' }
                                }
                            />

                            <Button
                                variant="contained"
                                onClick={() => {
                                    setModalMode('moove');
                                    setShowModal(true);
                                }}
                            >
                                Déplacer les articles sélectionnés
                            </Button>
                        </>
                    ) : null}
                </div>

                <TabPanel value={currentTab} index={1} sx={{ width: '50vw', margin: 'auto' }}>
                    <LoadingPaper
                        sx={{ padding: '24px', paddingBottom: '40px' }}
                        loading={isLoading}
                    >
                        <Typography mb="8px" style={fonts.inputHeader}>
                            Référence
                        </Typography>
                        <TextField disabled fullWidth value={data?.checkpointStoreRef} />
                        <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                            Nom
                        </Typography>
                        <TextField disabled fullWidth value={data?.label} />

                        <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                            Addresse
                        </Typography>
                        <TextField disabled fullWidth value={data?.address.address} />

                        {data?.address.addressAdditionnal && (
                            <>
                                <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                                    Adresse additionnelle
                                </Typography>
                                <TextField
                                    disabled
                                    fullWidth
                                    value={data?.address.addressAdditionnal}
                                />
                            </>
                        )}

                        <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                            Ville
                        </Typography>
                        <TextField disabled fullWidth value={data?.address.city} />

                        <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                            Code postal
                        </Typography>
                        <TextField disabled fullWidth value={data?.address.postalCode} />
                    </LoadingPaper>
                </TabPanel>

                <DivTabPanel value={currentTab} index={0} style={{ marginTop: 0 }}>
                    {selectedProduct === undefined && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <StockCard
                                    mr
                                    flex
                                    title="NB D'ARTICLES TOTAL EN STOCK"
                                    content={stock.data?.metadata.articleCount}
                                />
                                <StockCard
                                    mr
                                    flex
                                    title="NB D'ARTICLES ANTI GASPI EN STOCK"
                                    content={stock.data?.metadata.antiGaspiArticleCount}
                                />

                                <StockCard
                                    flex
                                    title="NB D'ARTICLES EXPIRES EN STOCK"
                                    content={stock.data?.metadata.expiredArticleCount}
                                />
                            </div>
                            <div style={{ padding: 10 }} />
                            <div style={{ display: 'flex' }}>
                                <StockCard
                                    mr
                                    flex
                                    title="VALORISATION TOTALE DES ARTICLES EN STOCK"
                                    content={priceToString(
                                        stock.data?.metadata.totalStockValue ?? 0
                                    )}
                                />
                                <StockCard
                                    mr
                                    flex
                                    title="VALORISATION DES ARTICLES ANTI GASPI EN STOCK"
                                    content={priceToString(
                                        stock.data?.metadata.totalAntiGaspiStockValue ?? 0
                                    )}
                                />

                                <StockCard
                                    flex
                                    title="VALORISATION TOTALE DES ARTICLES EXPIRES EN STOCK"
                                    content={priceToString(
                                        stock.data?.metadata.totalExpiredStockValue ?? 0
                                    )}
                                />
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    paddingTop: '16px',
                                }}
                            >
                                {currentTab === 0 && selectedProduct === undefined ? (
                                    <>
                                        {isStockAdmin ? (
                                            <ConfirmButton
                                                buttonText="Supprimer les articles"
                                                buttonProps={{
                                                    variant: 'outlined',
                                                    sx: {
                                                        marginRight: '16px',
                                                        height: '42px',
                                                        ...ButtonOverrides.redOutlined,
                                                    },
                                                    disabled: cleanStoreArticlesMut.isLoading,
                                                }}
                                                description="Etes-vous sur de vouloir supprimer tous les articles en stock de cette boutique ? Cette action est irréversible et bloquera toutes les ventes jusqu'au prochain inventaire"
                                                onConfirm={() => {
                                                    return cleanStoreArticlesMut.mutateAsync(
                                                        storeId
                                                    );
                                                }}
                                                title="Supprimer tous les articles ?"
                                                confirmButton={
                                                    cleanStoreArticlesMut.isLoading
                                                        ? {
                                                              loading: true,
                                                              text: 'Suppression en cours     ',
                                                          }
                                                        : { text: 'Supprimer' }
                                                }
                                            />
                                        ) : null}

                                        {isStockAdmin ? (
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    setModalMode('moove');
                                                    setShowModal(true);
                                                }}
                                            >
                                                Déplacer tous les articles
                                            </Button>
                                        ) : null}
                                        <div style={{ padding: 10 }} />
                                        {data?.storeType === 'BOUTIQUE' ||
                                        data?.storeType === 'SHIPPING' ? (
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    setModalMode('command');
                                                    setShowModal(true);
                                                }}
                                                disabled={isLoadingBdl || isLoading}
                                            >
                                                <DownloadIcon />
                                                {data?.storeType === 'BOUTIQUE'
                                                    ? 'Générer un bon de commande'
                                                    : 'Générer un bon de livraison'}
                                            </Button>
                                        ) : null}
                                        <div style={{ padding: 10 }} />
                                        <StoreCSVExportButton
                                            onlySelected
                                            selectedStores={[storeId]}
                                        />
                                        <div style={{ padding: 10 }} />
                                        {data?.storeType === 'BOUTIQUE' ? (
                                            <Button
                                                variant="contained"
                                                onClick={handleDownloadAuditFile}
                                                disabled={loadingAudit}
                                            >
                                                <DownloadIcon />
                                                Export audit
                                            </Button>
                                        ) : null}
                                        <div style={{ padding: 10 }} />
                                        {data?.storeType === 'BOUTIQUE' && isStockAdmin ? (
                                            <Button
                                                variant="contained"
                                                onClick={handleSetQualityAuditModal}
                                                disabled={isLoading}
                                            >
                                                <DownloadIcon />
                                                Audit qualité des livraisons
                                            </Button>
                                        ) : null}
                                    </>
                                ) : null}
                            </div>
                            <Paper
                                sx={{
                                    marginTop: '16px',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '16px',
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        label="Recherche"
                                        value={stockSearch}
                                        onChange={(e) => setStockSearch(e.target.value)}
                                    />
                                    <div style={{ flexGrow: 1 }} />
                                </div>
                                <SimpleDataGrid
                                    columns={stockColDef}
                                    rows={stockRows}
                                    loading={stock.isLoading}
                                    checkboxSelection={false}
                                    sx={DataGridOverrides.wrapHeaderNames}
                                    onRowClick={(row) => setSelectedProduct(row.id as number)}
                                />
                            </Paper>
                        </>
                    )}
                    {selectedProduct !== undefined && (
                        <Paper
                            sx={{
                                marginTop: '16px',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                                <Select
                                    value={articleState}
                                    onChange={(e) =>
                                        setArticleState(e.target.value as FilterArticleState)
                                    }
                                >
                                    <MenuItem value="all">Tout</MenuItem>
                                    <MenuItem value="antigaspi">Anti-gaspi</MenuItem>
                                    <MenuItem value="expired">Expiré</MenuItem>
                                    <MenuItem value="normal">Normal</MenuItem>
                                </Select>
                                <div style={{ flexGrow: 1 }} />
                            </div>
                            <SimpleDataGrid
                                columns={articlesColDef}
                                rows={articlesRows}
                                getRowId={(row) => row.id}
                                loading={articles.isLoading}
                                checkboxSelection
                                onRowSelectionModelChange={(newSelection) =>
                                    setSelectedArticles(newSelection as number[])
                                }
                                sx={DataGridOverrides.wrapHeaderNames}
                            />
                        </Paper>
                    )}
                </DivTabPanel>
            </Page>
        </>
    );
};

export default StoreDetailsPage;
