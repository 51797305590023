import { MenuType } from '@gozoki/tools';

// Base type for data with timestamps
export interface TimestampsAndID {
    id: number;

    createdAt: string;
    updatedAt: string;
}

// User data
export interface User extends TimestampsAndID {
    email: string;
    firstName: string;
    lastName: string;
    isReliable: boolean;
    isBanned: boolean;
    unBanAmount: number | null;
    adminRights: number;
    worksConcilId: number | null;
    studentDocumentRectoUrl: string | null;
    studentDocumentVersoUrl: string | null;
    isStudent: boolean;
    isValidatedStudent: boolean | null; // null means not validated yet
    storeId: number | null;
}

export interface AttachmentFile {
    name: string;
    extname: string;
    size: number;
    mimeType: string;
    url?: string; // Depends on preComputeUrl from the backend
}

// Category data
export interface Category extends TimestampsAndID {
    name: string;
    image?: AttachmentFile;
    categoryId: number | null;
    isSubCategory: boolean;
    description?: string;
    rank: number;
}

// Brand data
export interface Brand extends TimestampsAndID {
    name: string;
    image?: AttachmentFile; // Optional image
}

export const MENU_TYPES = ['PLAT', 'DESSERT', 'BOISSON'] as const;

export type MenuItemType = (typeof MENU_TYPES)[number];

export const MENU_TYPES_OPTIONS = [
    { label: 'Aucun' as const, value: null },
    ...MENU_TYPES.map((type) => ({
        label: type,
        value: type,
    })),
];

export const NEGOCE_TYPES = ['NEGOCE INTERNE', 'NEGOCE EXTERNE', 'SUSHI'] as const;

export type NegoceType = (typeof NEGOCE_TYPES)[number];

const NEGOCE_OPTIONS_OBJ: { [K in NegoceType]: string } = {
    'NEGOCE EXTERNE': 'Négoce externe',
    'NEGOCE INTERNE': 'Négoce interne',
    'SUSHI': 'Sushi',
};

export const NEGOCE_TYPES_OPTIONS: { value: NegoceType; label: string }[] = Object.entries(
    NEGOCE_OPTIONS_OBJ
).map(([value, label]) => ({ value: value as NegoceType, label }));

// Product data returned by the Index query
export interface Product extends TimestampsAndID {
    // Product label
    reference: string;
    dun14: string | null;
    label: string;

    // Product weight and price
    weight: number; // grams
    price: number; // euros
    weightPrice: number; // centimes per gram
    sellPrice: number; // euros
    sellWeightPrice: number; // centimes per gram

    // Product description
    description: string;
    nutriscore: 'A' | 'B' | 'C' | 'D' | 'E' | null;
    conservation: string;
    preparationMode: string | null;
    allergens: string | null;
    ingredients: string;
    antiGaspi: number; // Number before DLC to warn the user
    portions: string | null;

    // Metadata
    isNew: boolean;
    isActive: boolean;
    colisage: number;
    colisWeight: number; // grams

    // Additional data from other tables
    brandId: number;
    brand: Brand;

    menuType: MenuItemType | null;
    menuExtraPrice: number | null;
    copilotCode: string | null;
    negoceType: NegoceType;

    categories: Category[];

    nutritionalValueId: number;
    nutritionalValues?: NutritionalValues;

    optimizedImageUrls: string[];
    optimizedPreviewImageUrl: string | null;

    // Attachments
    images?: ProductImage[];
    preparationVideo?: AttachmentFile; // This is a video
    previewImage?: AttachmentFile; // This is an image 9x16 vignette

    agPromo: number; // ingeter : 70 for 70%
    agPrice: number;
}

// Product data returned by the Show query (everything is loaded)
export interface LoadedProduct extends Product {
    // Attachments
    images: ProductImage[];
    nutritionalValues: NutritionalValues;
}

export interface CampaignInfos {
    label: string;
    reductionType: string;
    reductionValue: number;
}

export interface PromoProduct {
    product: Product;
    campaignInfos?: CampaignInfos;
    isAntiGaspi?: boolean;
}

export interface NutritionalValues extends TimestampsAndID {
    energyValue: string; // kcal
    fats: string; // micrograms (high precision with integer)
    proteins: string; // micrograms (high precision with integer)
    carbohydrates: string; // micrograms (high precision with integer)
    sugars: string; // micrograms (high precision with integer)
    polyols: string; // micrograms (high precision with integer)
    fibers: string; // micrograms (high precision with integer)
    sodium: string; // micrograms (high precision with integer)
    salt: string; // micrograms (high precision with integer)
    saturatedFats: string; // micrograms (high precision with integer)
}

export interface ProductImage extends TimestampsAndID {
    productId: number;
    image: AttachmentFile;
}

export const CAMPAIGN_TARGETS = ['Produit', 'Utilisateur', 'Menu', 'Panier'] as const;
export type CampaignTarget = (typeof CAMPAIGN_TARGETS)[number];
export const CAMPAIGN_TYPES = ['Taux', 'BRI', 'ONE_FOR_ONE'] as const;
export type CampaignType = (typeof CAMPAIGN_TYPES)[number];

// Base campaign, with no target relation preloaded (used for the index query, that returns everything)
export interface PromoCampaignBase extends TimestampsAndID {
    type: CampaignTarget;
    name: string;
    isActive: boolean;

    isMain: boolean;
    alreadyUsed: boolean;

    reductionType: CampaignType;
    reductionValue: number;

    startDate: string;
    endDate: string;
    isPublic: boolean;
    publicCode: string | null;
}

export interface UserPromoCampaign extends PromoCampaignBase {
    type: 'Utilisateur';
    userTargets: User[];
    storeTargets: Store[];
}

export interface ProductPromoCampaign extends PromoCampaignBase {
    type: 'Produit';
    productTargets: Product[];
    storeTargets: Store[];
}

export interface MenuPromoCampaign extends PromoCampaignBase {
    type: 'Menu';
    menuTargets: string[];
    storeTargets: Store[];
}

export interface UserPromoCode extends TimestampsAndID {
    promoCampaign: PromoCampaignBase;
    numberOfUses: number;
    isMain: boolean;
    alreadyUsed: boolean;
}

// Campaign with loaded target (used in the show query, that returns only a specific campaign)
export type PromoCampaign = UserPromoCampaign | ProductPromoCampaign | MenuPromoCampaign;

/** Helper : get a list of target IDs */
export const getTargetIds = (campaign: PromoCampaign): (number | string)[] => {
    if (campaign.type === 'Produit') {
        return campaign.productTargets.map((product) => product.id);
    }
    if (campaign.type === 'Utilisateur') {
        return campaign.userTargets.map((user) => user.id);
    }
    if (campaign.type === 'Menu') {
        return campaign.menuTargets;
    }
    return [];
};

/** Helper : get a list of store references */
export const getStoreTargetRefs = (campaign: PromoCampaign): string[] => {
    if (campaign.storeTargets) {
        return campaign.storeTargets.map((store) => store.checkpointStoreRef);
    }
    return [];
};

// Store data
export interface Address extends TimestampsAndID {
    address: string;
    addressAdditionnal?: string;
    postalCode: string;
    city: string;
}

export const STORE_TYPES = ['BOUTIQUE', 'SHIPPING', 'WAREHOUSE', 'POUBELLE'] as const;

export type StoreType = (typeof STORE_TYPES)[number];

export interface Store extends TimestampsAndID {
    checkpointStoreRef: string;
    label: string;
    addressId: number;
    address: Address;
    storeType: StoreType;
}

export interface Kiosk extends TimestampsAndID {
    label: string;
    store: Store;
    supportMode: boolean;
    cartId: number;
    transaction: string;
}

export const UNIQUE_ARTICLE_SOLD_STATUSES = [
    'NORMAL',
    'ANTI_GASPI',
    'MENU',
    'PROMO',
    'PROMO_MENU',
    'STUDENT_MENU',
    'FREE',
    'WORKS_CONCIL',
    'STUDENT',
    'OTHER',
    'BRI',
] as const;

export type UniqueArticleSoldStatus = (typeof UNIQUE_ARTICLE_SOLD_STATUSES)[number];

export const UNIQUE_ARTICLE_PAID_WAY = ['APP', 'CPI'] as const;

export type UniqueArticlePaidWay = (typeof UNIQUE_ARTICLE_PAID_WAY)[number];

export const UNIQUE_ARTICLE_SHOPPING_WAY = ['IN_STORE', 'CLICK_AND_COLLECT'] as const;

export type UniqueArticleShoppingWay = (typeof UNIQUE_ARTICLE_SHOPPING_WAY)[number];
export interface UniqueArticle extends TimestampsAndID {
    checkpointArticleRef: string;
    productReference: string;
    storeReference: string;
    weight: number; // Grams
    dlc: string; // ISO date string

    displayedDlc: string; // Computed field, formatted like 27/02/2024
    isAntiGaspi: boolean; // Computed field
    isExpired: boolean; // Computed field
    isConfirmed: boolean; // Computed field

    product: Product; // Preloaded by default
    store: Store; // not preloaded by default

    state: UniqueArticleState;

    soldPrice: number | null;
    soldStatus: UniqueArticleSoldStatus | null;
    paidWay: UniqueArticlePaidWay | null;
    shoppingWay: UniqueArticleShoppingWay | null;
    buyingPrice: number | null;

    warehouseAt: string | null; // ISO date string
    shippingAt: string | null; // ISO date string
    boutiqueAt: string | null; // ISO date string
    lostAt: string | null; // ISO date string
}

export interface WorksConcilPreview extends TimestampsAndID {
    label: string;
    isActive: boolean;
    maxMonthAmountPerEmployee: number;
    gozokiPercentage: number;
    worksConcilPercentage: number;
    numberOfEmployees: number;
    totalUsedAmount: number;
    numberOfActivesEmployees: number;
    startDate: string; // ISO date string
    endDate: string; // ISO date string
}

export interface WorksConcil extends TimestampsAndID {
    label: string;
    isActive: boolean;
    isOnDate: boolean;

    companyLabel: string;
    companyCity: string;
    companyAddress: string;
    companySiren: string;

    maxMonthAmountPerEmployee: number;
    gozokiPercentage: number;
    worksConcilPercentage: number;

    startDate: string; // ISO date string
    endDate: string; // ISO date string

    emails: string[];
    employees: User[];
}

export const CART_STATUSES = ['pending', 'validated', 'cancelled', 'stolen'] as const;

export type CartStatus = (typeof CART_STATUSES)[number];

export interface Cart extends TimestampsAndID {
    userId: number | null;
    status: CartStatus;
    user?: User; // Not preloaded by default !
    report: CartReportWithTaxes;
    articles: UniqueArticle[]; // Preloaded by default
    buyingPrice: number | null;
    amountTotal?: number;
    store: Store; // Preloaded by default
}

export interface CartsWithReportUrl extends Cart {
    reportUrl: string;
}

export interface CartReport {
    items: FinalCartUnit[];
    subTotal: number;
    otherPromotions: CartPromotion[];
    total: number;
}

export interface CartPromotion {
    type: PromoType;
    label: string;
    amount: number;
}

export interface CartTax {
    rate: number;
    amount: number;
}

interface ItemBase {
    label: string;
    unitPrice: number;
    quantity: number;
    amount: number;
}

export interface MenuItem extends ItemBase {
    type: MenuType;
    content: UniqueArticle[];
}

export interface ProductItem extends ItemBase {
    type: 'ARTICLE';
    productReference: string;
    article: UniqueArticle;
}

export type CartComputeItem = MenuItem | ProductItem;

const PROMO_TYPES = ['ANTI_GASPI', 'ONE_FOR_ONE', 'WORKS_CONCIL', 'BRI'] as const;

export type PromoType = (typeof PROMO_TYPES)[number];

export interface CartReportWithTaxes extends CartReport {
    totalExTax: number;
    taxes: CartTax[];
}
export interface CartUnit {
    item: CartComputeItem;
    promotions: CartPromotion[];
}

export interface FinalCartUnit {
    label: string;
    unitPrice: number;
    quantity: number;
    amount: number;
    type: 'ARTICLE' | 'MENU';
    content: { label: string }[];
    imageUrl: string | null;
    promotions: CartPromotion[];
}
// Stock data
export interface StockMetadata {
    articleCount: number;
    antiGaspiArticleCount: number;
    expiredArticleCount: number;
    totalStockValue: number;
    totalAntiGaspiStockValue: number;
    totalExpiredStockValue: number;
}

export interface StockEntry {
    id: number;
    imageUrl: string | null;
    reference: string;
    label: string;
    colisage: number;
    weight: number | null;

    buyingPrice: number;

    stock: number;
    agStock: number;
    expiredStock: number;
    minimumStock: number;
    weekEndMinimumStock: number;
    dun14: string;

    totalValue: number;
    totalValueAntiGaspi: number;
    totalValueExpired: number;
    antiGaspiValue: number;
    reaproNeeds: number;
    totalWeight: number;
    reaproWeight: number;
    weekEndReaproNeeds: number;
    weekEndReaproWeight: number;
    articlesReaproNeeds: number;
    weekEndArticlesReaproNeeds: number;
}

export interface StockData {
    metadata: StockMetadata;
    rows: StockEntry[];
}

export interface QrCode {
    token: string;
    image?: string;
}

export interface PayzenPaymentToken {
    paymentMethodToken: string;
    paymentMethodType: 'CARD' | 'SDD';
    creationDate: string;
    cancelationDate: string | null;
    status: 'ACTIVE' | 'CANCELLED' | 'EXPIRED' | 'ALL';
    tokenDetails: {
        expiryMonth: number;
        expiryYear: number;
        effectiveBrand: string;
        pan: string;
    };
}

export interface PaymentResponse {
    _type: string;
    customer: {
        email: string;
        reference: string | null;
    };
    orderDetails: {
        _type: string;
        orderId: string;
        orderamountTotal: number;
    };
    serverDate: string;
    orderStatus: string;
    transactions: Array<{
        paymentMethodToken: string;
        paymentMethodType: string;
        shopId: string;
        status: string;
        uuid: string;
    }>;
}
export const UNIQUE_ARTICLE_STATES = [
    'SOLD',
    'IN_STOCK',
    'FAULTY',
    'STOLEN',
    'DESTROYED',
    'IN_CART',
    'DELETED', // use as a soft delete to keep the history
    'DEFECTIVE_PACKAGING',
] as const;

export type UniqueArticleState = (typeof UNIQUE_ARTICLE_STATES)[number];

export const DEFAULT_UNIQUE_ARTICLE_STATE: UniqueArticleState = 'IN_STOCK';
export interface DailyDashboardKpi {
    id: number;
    storeId: number;
    date: string;
    ca: number;
    caHt: number;
    caAntiGaspi: number;
    caAntiGaspiHt: number;
    buyingPrice: number;
    productsSoldCount: number;
    antiGaspiProductsSold: number;
    cartsCount: number;
    productsSoldMap: { [key: string]: { ref: string; count: number } };
    productsSoldMapByCa: { [key: string]: { ref: string; ca: number } };
    categoriesSoldMap: { [key: number]: { id: number; count: number } };
    categoriesSoldMapByCa: { [key: number]: { id: number; ca: number } };
    appUsersCount: number;
    appNewUsersCount: number;
    appDeletedUsersCount: number;
    appActivatedUsersCount: number;
    appCartsCount: number;
    appUsersList: number[];
    studentAmount: number;
    studentCartsCount: number;
    studentCartsCa: number;
    studentUsersCount: number;
    wcCa: number;
    wcCartsCount: number;
    wcGozokiAmount: number;
    wcCompanyAmount: number;
}

export interface DailyStockKpi {
    id: number;
    storeId: number;
    date: string;
    articlesSoldMap: { [key: number]: { ref: string; count: number } };
    articlesStolenMap: { [key: number]: { ref: string; count: number } };
    articlesFaultyMap: { [key: number]: { ref: string; count: number } };
    articlesDestroyedMap: { [key: number]: { ref: string; count: number } };
    articlesExpiredMap: { [key: number]: { ref: string; count: number } };
    articlesDefectiveMap: { [key: number]: { ref: string; count: number } };
    articlesSoldCount: number;
    articlesFaultyCount: number;
    articlesDestroyedCount: number;
    articlesStolenCount: number;
    articlesExpiredCount: number;
    articlesDefectiveCount: number;
}

export interface AccountingKpis {
    date: string;
    main: {
        internalNegoceHt: number;
        internalNegoceTva: number;
        externalNegoceHt: number;
        externalNegoceTva: number;
        sushisTtc: number;
        amountCb: number;
        amountConecs: number;
        unPaidAmount: number;
        promoAmountHt: number;
        promoAmountTva: number;
    };
}

export interface HoursData {
    time: number;
    count: number;
    amount: number;
}

export interface TimeSpentData {
    time: number;
    averageTimeInShop: number;
    averageTimeInDesk: number;
}

export interface Lot {
    lot: string;
    ean: string;
    nb: number;
    label: string;
    dlc: string;
    tracking: string;
    blocked: boolean;
    comment: string;
    blockedAt: string | null;
    blockedBy: string | null;
    unBlockedAt: string | null;
    articles: string[];
}

export interface GlobalParams {
    studentPromoPercentage: number;
    menuPlatBoissonName: string;
    menuPlatBoissonPrice: number;
    menuPlatBoissonDessertName: string;
    menuPlatBoissonDessertPrice: number;
}

export interface StoreStock {
    storeReference: string;
    stock: number;
    label: string;
}

export interface NotificationPayload {
    path: string | null | undefined;
}

export interface Notification {
    id: number;
    title: string;
    subTitle: string;
    text: string;
    onlyUsers: boolean;
    shopIds: number[];
    recipients: number;
    interactions: number;
    createdAt: Date;
    updatedAt: Date;
    data: NotificationPayload | null;
}

export const KIOSK_TYPES = ['CHECK_IN', 'CHECK_OUT'] as const;
export type KioskType = (typeof KIOSK_TYPES)[number];
