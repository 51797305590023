import { isNumber, parseFrenchFloat } from '@gozoki/tools';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const zPromoCampaignForm = z.object({
    type: z
        .string()
        .nonempty({ message: 'Le type de la campagne est requis' })
        .max(30, { message: 'Le type de la campagne doit contenir au maximum 30 caractères' }),
    name: z
        .string()
        .nonempty({ message: 'Le nom de la campagne est requis' })
        .max(30, { message: 'Le nom de la campagne doit contenir au maximum 30 caractères' }),

    targetIds: z.array(z.union([z.number(), z.string()])), // ID of target products, users...it could be a string for the menus ids
    storeTargetRefs: z.array(z.string()), // References of target stores

    reductionType: z
        .string()
        .nonempty({ message: 'Le type de réduction est requis' })
        .max(30, { message: 'Le type de réduction doit contenir au maximum 30 caractères' }),
    reductionValue: z
        .string()
        .refine(isNumber, { message: 'La valeur de réduction doit être un nombre' })
        .transform(parseFrenchFloat),

    // Date stored as a string for react-hook-form compatibility, but parsed for validation
    startDate: z.string().nonempty({ message: 'La date de début est requise' }),
    endDate: z.string().nonempty({ message: 'La date de fin est requise' }),
    campaignType: z.string().nonempty({ message: 'Le type de campagne est requis' }),
    publicCode: z.string().nullable(),
});

export type PromoCampaignForm = z.infer<typeof zPromoCampaignForm>;

// Form hook
export const usePromoCampaignForm = (defaultValues?: PromoCampaignForm) => {
    const form = useForm<PromoCampaignForm>({
        // Validation function
        resolver: zodResolver(zPromoCampaignForm),
        defaultValues: {
            type: 'Produit',
            name: '',

            reductionType: 'Taux',
            storeTargetRefs: [],
            campaignType: 'private',
            publicCode: '',
            targetIds: [],

            ...defaultValues,

            // Reformat some values to match the required validation
            reductionValue: defaultValues?.reductionValue.toString() as number | undefined,
        },
    });

    return form;
};
